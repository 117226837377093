import React, {Fragment, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useDimension} from '../Hooks/AppHooks';
import RefineBar from '../Components/RefineBar';
import ProductCollection from '../Components/ProductCollection';
import ProductGrid from '../Components/ProductGrid';
import useCalcProductGridLayout from '../Utils/useCalcProductGridLayout';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from '../Components/RectButton';
import {Actions, Context} from '../Contexts/AppContext';
import {convert, deconvert} from '../Utils/FilterUtil';
import * as L from '../Utils/Lang';
import queryString from 'query-string';
import CustomNav from '../Components/CustomNav';
import {MiniCartIcon} from '../Components/MiniCartIcon';
import {Color, FlexColCenter} from '../Components/Widget';
import FixedRatioImage from '../Components/FixedRatioImage';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const Line = styled.div`
  height: 1px;
  background-color: rgba(20, 20, 20, 0.1);
  margin: 80px 0;
`;

function SeriesPage(props) {
  const {location} = props;
  const {mobile, tablet, desktop} = useDimension();
  const smallDevice = mobile || tablet;
  const queryParams = queryString.parse(location.search);
  const {id: seriesId} = queryParams;
  const [brandData, setBrandData] = useState(null);
  const [products, setProducts] = useState([]);
  const [fetchParam, setFetchParam] = useState({});
  const {filter = [], sort = '-created', offset = 0} = fetchParam;
  const {
    collections = [],
    brand = {},
    sortOptions = ['+price', '-price', '-created'],
  } = brandData || {};
  const app = useContext(Context);
  const DEFAULT_LIMIT = 48;
  const [filterOptions, setFilterOptions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const isMember = !!app.currentUser;

  useEffect(() => {
    async function fetchData() {
      try {
        Actions.setLoading(true);
        const res = await Actions.fetchSeries({
          seriesId: seriesId,
        });
        setFetchParam({filter: res.filterOptions, offset: 0});
        setFilterOptions(convert(res.filterOptions));
        setBrandData(res);
      } catch (err) {
        console.error(err);
      }
      Actions.setLoading(false);
    }
    fetchData();
  }, [seriesId, isMember]);

  const countSelectedFilter = () => {
    let total = 0;
    for (const cfg of filterOptions) {
      total += (cfg.options || []).filter(opt => opt.selected).length;
    }
    return total;
  };

  const selectedFilterCnt = countSelectedFilter();

  useEffect(() => {
    async function fetchData() {
      try {
        Actions.setLoading(true);
        const data = await Actions.fetchProducts({
          brand: app.activeBrand.toLocaleLowerCase(),
          collectionId: [],
          filter,
          sort,
          offset: 0,
          seriesId: seriesId,
          limit: DEFAULT_LIMIT,
        });
        setProducts(data.products);
        setTotalCount(data.total_count);
        window.scrollTo(0, 0);
      } catch (ex) {
        console.error(ex);
      }
      Actions.setLoading(false);
    }

    if (selectedFilterCnt) {
      fetchData();
    }
  }, [selectedFilterCnt, filter, sort, app.activeBrand, seriesId]);

  async function fetchNextPage() {
    Actions.setLoading(true);
    const data = await Actions.fetchProducts({
      brand: app.activeBrand.toLocaleLowerCase(),
      collectionId: [],
      filter,
      sort,
      offset: offset + DEFAULT_LIMIT,
      limit: DEFAULT_LIMIT,
      seriesId: seriesId,
    });
    const nextProducts = data.products;
    Actions.setLoading(false);
    const prevY = window.scrollY;
    setProducts([...products, ...nextProducts]);
    window.scrollTo({top: prevY});
    setFetchParam({...fetchParam, offset: offset + DEFAULT_LIMIT});
  }

  function updateFilter(nextConfig) {
    setFilterOptions(nextConfig);
    setFetchParam({
      ...fetchParam,
      offset: 0,
      filter: deconvert(nextConfig),
    });
  }

  function sortProducts(_collections) {
    return _collections.map(collection => ({
      ...collection,
      products: collection.products.sort((p1, p2) => {
        if (sort === '-price') {
          return p2.price - p1.price;
        } else if (sort === '+price') {
          return p1.price - p2.price;
        }
      }),
    }));
  }

  async function updateSort(nextSort) {
    setFetchParam({
      ...fetchParam,
      offset: 0,
      sort: nextSort,
    });

    if (selectedFilterCnt === 0) {
      // since in this case we don't need to fetch any data,
      // so we show a loading UI to hint the user that product order is changed
      Actions.setLoading(true);
      await delay(600);
      Actions.setLoading(false);
    }
  }

  const {itemPerRow, itemWidth, gutter} = useCalcProductGridLayout();

  const ratio = app.brandProductImageRatio[app.activeBrand.toLocaleLowerCase()];

  const commonCollectionProps = {
    itemWidth,
    itemPerRow,
    smallDevice,
    gutter,
    ratio,
  };

  const [
    collectionA,
    collectionB,
    collectionC,
    collectionD,
    collectionE,
    collectionF,
  ] = sortProducts(collections);

  if (!brandData) {
    return <Wrapper style={{height: '100vh'}} />;
  }

  return (
    <Wrapper key={seriesId} smallDevice={smallDevice} data-nav-type="revert">
      {smallDevice && (
        <CustomNav initNavType="revert" right={<MiniCartIcon />} />
      )}

      <FixedRatioImage
        width={'100%'}
        ratio={desktop ? 540 / 1440 : 640 / 750}
        image={desktop ? brand.image.original : brand.image.mobile}
        mode={'cover'}
        data-nav-type="revert"
      />

      <BrandInfo data-nav-type="solid">
        <h2>{L.d(brand.title)}</h2>
        <p>{L.d(brand.description)}</p>
      </BrandInfo>
      <div className="content">
        <RefineBar
          smallDevice={smallDevice}
          filter={filterOptions}
          selectedFilterCnt={selectedFilterCnt}
          sort={sort}
          sortOptions={sortOptions}
          onUpdateFilter={updateFilter}
          onUpdateSort={updateSort}
        />

        <div style={{height: 40}} />
        {(selectedFilterCnt === 0 && (
          <>
            {collectionA?.products.length > 0 && (
              <ProductCollection
                collectionType="A"
                collection={collectionA}
                {...commonCollectionProps}
              />
            )}
            {collectionB?.products.length > 0 && (
              <Fragment>
                <Line />
                <ProductCollection
                  collectionType="B"
                  collection={collectionB}
                  {...commonCollectionProps}
                />
              </Fragment>
            )}
            {collectionC?.products.length > 0 && (
              <Fragment>
                <Line />
                <ProductCollection
                  collectionType="C"
                  collection={collectionC}
                  {...commonCollectionProps}
                />
              </Fragment>
            )}
            {collectionD?.products.length > 0 && (
              <Fragment>
                <Line />
                <ProductCollection
                  collectionType="D"
                  collection={collectionD}
                  {...commonCollectionProps}
                />
              </Fragment>
            )}
            {collectionE?.products.length > 0 && (
              <Fragment>
                <Line />
                <ProductCollection
                  collectionType="E"
                  collection={collectionE}
                  {...commonCollectionProps}
                />
              </Fragment>
            )}
            {collectionF?.products.length > 0 && (
              <Fragment>
                <Line />
                <ProductCollection
                  collectionType="F"
                  collection={collectionF}
                  {...commonCollectionProps}
                />
              </Fragment>
            )}
          </>
        )) || (
          <Fragment>
            {products.length > 0 ? (
              <ProductGrid
                products={products}
                itemPerRow={itemPerRow}
                itemWidth={itemWidth}
              />
            ) : (
              <FlexColCenter
                style={{
                  fontSize: 16,
                  fontWeight: 300,
                  color: Color.mainDark_70,
                  margin: mobile ? '80px 0 227px 0' : '160px 0 403px 0',
                  textAlign: 'center',
                }}>
                未找到任何商品，
                {mobile && <br />}
                請嘗試刪除部分篩選條件
              </FlexColCenter>
            )}
            {products.length < totalCount && (
              <RectButton
                skin={BUTTON_SKIN.DEFAULT}
                size={smallDevice ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}
                style={{margin: '0 auto', width: '160px'}}
                onClick={fetchNextPage}
                text="更多商品"
              />
            )}
          </Fragment>
        )}
        <div style={{height: 60}} />
      </div>
    </Wrapper>
  );
}

const BigBanner = styled.div`
  width: 100vw;

  & > .image {
    height: ${props => (props.smallDevice ? '480px' : '540px')};
    position: relative;
    background-color: #eee;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const BrandInfo = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 48px 20px 20px 20px;
  text-align: center;

  & > h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  & > p {
    margin-top: 8px;
    font-size: 14px;
    color: rgba(20, 20, 20, 0.7);
  }
`;

const Wrapper = styled.div`
  & > .content {
    padding: 0 ${props => (props.smallDevice ? 20 : 40)}px;
    padding-bottom: 0;
    max-width: 1440px;
    margin: 0 auto;
  }

  & > .category {
    font-size: 30px;
    line-height: 38px;
  }
`;

export default SeriesPage;
