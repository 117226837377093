import React from 'react';
import Carousel from './Carousel';
import * as Widget from './Widget';
import FixedRatioImage from './FixedRatioImage';
import {ChevronLeft, ChevronRight} from './SvgIcon';
import styled from 'styled-components';
import * as L from '../Utils/Lang';
import {getFormatPrice} from '../Utils/ProductUtil';
import {NavActions} from '../Contexts/AppContext';

function CollectionCarousel(props) {
  const {width, height, collection, alt, style = {}, isSquare} = props;

  if (collection.products.length === 0) {
    return null;
  }

  return (
    <Carousel
      width={width}
      height={height}
      style={style}
      data={collection.products}
      renderPrev={null}
      renderNext={null}
      renderDots={({currIdx, goToIndex}) => {
        const item = collection.products[currIdx];
        if (alt) {
          return (
            <Widget.FlexCol
              style={{
                width: '100%',
                backgroundColor: 'white',
                padding: 0,
                position: 'absolute',
                left: 0,
                bottom: 0,
              }}>
              <div style={{flex: 1}}>
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}>
                  {L.d(item.name)}
                </div>
                <div
                  style={{
                    fontSize: 16,
                    marginTop: 8,
                    textAlign: 'center',
                    color: 'darkgray',
                  }}>
                  NT {getFormatPrice(item.price)}
                </div>
              </div>
              <Widget.FlexRow style={{alignSelf: 'center', marginTop: 20}}>
                <Widget.FlexCenter
                  onClick={() => goToIndex(currIdx - 1)}
                  style={{
                    width: 32,
                    height: 32,
                    border: '1px solid black',
                    userSelect: 'none',
                  }}>
                  <ChevronLeft />
                </Widget.FlexCenter>
                <div
                  style={{
                    margin: 10,
                    width: 60,
                    textAlign: 'center',
                  }}>{`${currIdx + 1} - ${collection.products.length}`}</div>
                <Widget.FlexCenter
                  onClick={() => goToIndex(currIdx + 1)}
                  style={{
                    width: 32,
                    height: 32,
                    border: '1px solid black',
                    userSelect: 'none',
                  }}>
                  <ChevronRight />
                </Widget.FlexCenter>
              </Widget.FlexRow>
            </Widget.FlexCol>
          );
        }
        return (
          <Widget.FlexRow
            style={{
              width: isSquare ? '100%' : 'calc(100% - 30px)',
              backgroundColor: 'white',
              padding: isSquare ? 0 : 32,
              position: 'absolute',
              left: 0,
              bottom: 0,
            }}>
            <div style={{flex: 1}}>
              <div style={{fontSize: 16, fontWeight: 'bold'}}>
                {L.d(item.name)}
              </div>
              <div style={{fontSize: 16, marginTop: 8, color: 'darkgray'}}>
                NT {getFormatPrice(item.price)}
              </div>
            </div>
            <Widget.FlexRow>
              <Widget.FlexCenter
                onClick={() => goToIndex(currIdx - 1)}
                style={{
                  width: 32,
                  height: 32,
                  border: '1px solid black',
                  userSelect: 'none',
                }}>
                <ChevronLeft />
              </Widget.FlexCenter>
              <Widget.FlexCenter
                onClick={() => goToIndex(currIdx + 1)}
                style={{
                  width: 32,
                  height: 32,
                  border: '1px solid black',
                  borderLeft: 'none',
                  userSelect: 'none',
                }}>
                <ChevronRight />
              </Widget.FlexCenter>
            </Widget.FlexRow>
          </Widget.FlexRow>
        );
      }}
      renderItem={({item}) => (
        <ItemWrapper
          onClick={() => {
            NavActions.navigate(`/product?id=${item.id}`);
          }}
          style={{width: width, height: height}}>
          {!alt && (
            <FixedRatioImage
              image={item.image[0]}
              alt="cut banner"
              ratio={isSquare ? 1 : 1.6}
              mode="cover"
              width={'100%'}
              extraCss={{backgroundPosition: 'center'}}
            />
          )}
          {alt && (
            <FixedRatioImage
              image={item.image}
              alt="cut banner"
              ratio={isSquare ? 1 : 1.6}
              mode="cover"
              width={'100%'}
              extraCss={{backgroundPosition: 'center'}}
            />
          )}
        </ItemWrapper>
      )}
      disableSwipe
    />
  );
}

const ItemWrapper = styled.div`
  position: relative;
  & :hover {
    cursor: pointer;
  }
`;

export default CollectionCarousel;
