import React, {Fragment} from 'react';
import styled from 'styled-components';
import ProductItem from './ProductItem';
import ProductGrid from './ProductGrid';
import CollectionCarousel from './ProductCollection.carousel';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from './RectButton';
import {FlexRow} from './Widget';
import FixedRatioImage from './FixedRatioImage';
import * as L from '../Utils/Lang';
import {useDimension} from '../Hooks/AppHooks';

function CollectionHeader(props) {
  const {collection = {}, style = {}} = props;
  const {title, description} = collection;

  return (
    <CollectionHeaderWrapper style={style}>
      <h2>{L.d(title)}</h2>
      <p>{L.d(description)}</p>
    </CollectionHeaderWrapper>
  );
}

const CollectionHeaderWrapper = styled.div`
  background-color: white;
  margin-bottom: 24px;

  & > h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    margin: 0 0 8px 0;
    padding: 0;
  }

  & > p {
    font-family: NotoSansCJKtc, Gotham-Book, serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    max-width: 430px;
  }
`;

const PRELOAD_PRODUCTS = {
  A: 6,
  B: 0,
  C: 4,
  D: 0,
  E: 4,
  F: 8,
};

function ProductCollection(props) {
  const {
    collection,
    itemWidth,
    itemPerRow,
    gutter,
    smallDevice,
    collectionType = 'A',
    ratio,
  } = props;
  const scrollPointRef = React.useRef(0);
  const [showAllProducts, setShowAllProducts] = React.useState(false);

  React.useLayoutEffect(() => {
    if (showAllProducts) {
      window.scrollTo({top: scrollPointRef.current});
    }
  }, [showAllProducts]);

  const bannerWidth = 2 * itemWidth + gutter;
  const isSquare = ratio === 1;

  const [product0, product1, product2, product3] = collection?.products || [];
  const preloadProductCnt = PRELOAD_PRODUCTS[collectionType];
  const moreProducts = collection.products.slice(preloadProductCnt);

  const loadMore = moreProducts.length > 0 && (
    <Fragment>
      <AnimExpand expand={showAllProducts}>
        <ProductGrid
          products={moreProducts}
          itemPerRow={itemPerRow}
          itemWidth={itemWidth}
        />
      </AnimExpand>
      {!showAllProducts && (
        <RectButton
          skin={BUTTON_SKIN.DEFAULT}
          onClick={() => {
            scrollPointRef.current = window.scrollY;
            setShowAllProducts(!showAllProducts);
          }}
          text={`更多商品`}
          size={BUTTON_SIZE.LARGE}
          style={{margin: '0 auto', width: '200px'}}
        />
      )}
    </Fragment>
  );

  return (
    <Fragment>
      {collectionType === 'A' && (
        <Fragment>
          <CollectionHeader
            collection={collection}
            style={
              smallDevice
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 25px',
                  }
                : {}
            }
          />
          <CollectionRow style={smallDevice ? {justifyContent: 'center'} : {}}>
            <FixedRatioImage
              image={collection?.image}
              alt="cut banner"
              ratio={isSquare ? 0.56 : 0.78}
              mode="cover"
              width={bannerWidth}
              extraCss={{backgroundPosition: 'center', marginBottom: '16px'}}
            />
            {smallDevice && (
              <FlexRow style={{justifyContent: 'space-between', width: '100%'}}>
                <ProductItem itemWidth={itemWidth} product={product0} />
                <ProductItem itemWidth={itemWidth} product={product1} />
              </FlexRow>
            )}
            {!smallDevice && (
              <>
                <ProductItem itemWidth={itemWidth} product={product0} />
                <ProductItem itemWidth={itemWidth} product={product1} />
              </>
            )}
          </CollectionRow>
          <ProductGrid
            products={collection.products.slice(2, 6)}
            itemPerRow={itemPerRow}
            itemWidth={itemWidth}
          />
          {loadMore}
        </Fragment>
      )}

      {collectionType === 'B' && (
        <>
          <CollectionHeader collection={collection} />
          <CollectionRow style={{justifyContent: 'flex-start'}}>
            <FixedRatioImage
              image={collection?.image}
              alt="cut banner"
              ratio={1.25}
              mode="cover"
              width={bannerWidth}
              extraCss={{backgroundPosition: 'center', marginBottom: '16px'}}
            />
            <CollectionCarousel
              collection={collection}
              width={
                smallDevice
                  ? bannerWidth
                  : isSquare
                  ? bannerWidth
                  : bannerWidth * 0.78
              }
              height={bannerWidth * 1.25}
              isSquare={isSquare}
              style={{
                marginLeft: smallDevice ? 0 : gutter,
                marginBottom: 16,
              }}
            />
          </CollectionRow>
        </>
      )}

      {collectionType === 'C' && (
        <>
          <CollectionRow style={{display: 'flex', alignItems: 'center'}}>
            <CollectionHeader
              collection={collection}
              style={
                smallDevice
                  ? {
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0 25px',
                    }
                  : {
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0 100px',
                    }
              }
            />
            <FixedRatioImage
              image={collection?.image}
              alt="cut banner"
              ratio={0.56}
              mode="cover"
              width={bannerWidth}
              extraCss={{backgroundPosition: 'center', marginBottom: '16px'}}
            />
          </CollectionRow>
          <ProductGrid
            products={collection.products.slice(0, 4)}
            itemPerRow={itemPerRow}
            itemWidth={itemWidth}
          />
          {loadMore}
        </>
      )}

      {collectionType === 'D' && (
        <>
          <CollectionHeader
            collection={collection}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          />
          <CollectionRow
            style={{justifyContent: 'space-evenly', alignItems: 'center'}}>
            <FixedRatioImage
              image={collection?.image}
              alt="cut banner"
              ratio={1.25}
              mode="cover"
              width={bannerWidth}
              extraCss={{backgroundPosition: 'center', marginBottom: '16px'}}
            />
            {!isSquare && (
              <CollectionCarousel
                collection={collection}
                width={smallDevice ? bannerWidth : bannerWidth * 0.6}
                height={smallDevice ? bannerWidth * 1.98 : bannerWidth * 1.25}
                isSquare={isSquare}
                style={{
                  marginLeft: smallDevice ? 0 : gutter,
                }}
                alt
              />
            )}
            {isSquare && (
              <CollectionCarousel
                collection={collection}
                width={smallDevice ? bannerWidth : bannerWidth * 0.62}
                height={smallDevice ? bannerWidth * 1.23 : bannerWidth}
                isSquare={isSquare}
                style={{
                  marginLeft: smallDevice ? 0 : gutter,
                }}
                alt
              />
            )}
          </CollectionRow>
        </>
      )}

      {collectionType === 'E' && !smallDevice && (
        <>
          <CollectionHeader
            collection={collection}
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0 100px',
            }}
          />
          <CollectionRow style={{alignItems: 'center'}}>
            <CollectionRow style={{width: bannerWidth}}>
              <ProductItem itemWidth={itemWidth} product={product0} />
              <ProductItem itemWidth={itemWidth} product={product1} />
              <ProductItem itemWidth={itemWidth} product={product2} />
              <ProductItem itemWidth={itemWidth} product={product3} />
            </CollectionRow>
            <FixedRatioImage
              image={collection?.image}
              alt="cut banner"
              ratio={isSquare ? 1.6 : 1.25}
              mode="cover"
              width={bannerWidth}
              extraCss={{backgroundPosition: 'center', marginBottom: '16px'}}
            />
          </CollectionRow>
          {loadMore}
        </>
      )}

      {collectionType === 'E' && smallDevice && (
        <>
          <CollectionHeader
            collection={collection}
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0 25px',
            }}
          />
          <CollectionRow>
            <FixedRatioImage
              image={collection?.image}
              alt="cut banner"
              ratio={1.25}
              mode="cover"
              width={bannerWidth}
              extraCss={{backgroundPosition: 'center', marginBottom: '16px'}}
            />
            <ProductItem itemWidth={itemWidth} product={product0} />
            <ProductItem itemWidth={itemWidth} product={product1} />
            <ProductItem itemWidth={itemWidth} product={product2} />
            <ProductItem itemWidth={itemWidth} product={product3} />
          </CollectionRow>
          {loadMore}
        </>
      )}

      {collectionType === 'F' && !smallDevice && (
        <>
          <div
            style={{
              width: '100%',
              position: 'relative',
              marginBottom: 32,
            }}>
            <FixedRatioImage
              image={collection?.image}
              alt="cut banner"
              ratio={0.56}
              mode="cover"
              width={bannerWidth * 1.365}
              extraCss={{backgroundPosition: 'center', marginBottom: '16px'}}
            />
            <CollectionHeader
              collection={collection}
              style={{
                position: 'absolute',
                width: bannerWidth * 0.7,
                bottom: 0,
                right: 0,
                padding: 40,
                marginBottom: 0,
                backgroundColor: 'white',
              }}
            />
          </div>
          <ProductGrid
            products={collection.products.slice(0, 8)}
            itemPerRow={itemPerRow}
            itemWidth={itemWidth}
          />
          {loadMore}
          <div style={{height: 80}} />
        </>
      )}

      {collectionType === 'F' && smallDevice && (
        <>
          <FixedRatioImage
            image={collection?.image}
            alt="cut banner"
            ratio={0.56}
            mode="cover"
            width={bannerWidth}
            extraCss={{backgroundPosition: 'center', marginBottom: '16px'}}
          />
          <CollectionHeader collection={collection} />
          <ProductGrid
            products={collection.products.slice(0, 8)}
            itemPerRow={itemPerRow}
            itemWidth={itemWidth}
          />
          {loadMore}
          <div style={{height: 80}} />
        </>
      )}
    </Fragment>
  );
}

const CollectionRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const AnimExpand = styled.div`
  transition: opacity 250ms;
  max-height: ${props => (props.expand ? 1000000 : 0)}px;
  opacity: ${props => (props.expand ? 1 : 0)};
  overflow: hidden;
`;

export default ProductCollection;
